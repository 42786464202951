import React from 'react';
import { useRouter } from 'next/router';
import { Box, Grid, IconButton, Link, Typography } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import theme from 'shared/theme';
import { useSession } from 'next-auth/react';

export const UI = () => {
  const routing = useRouter();

  const router = useRouter();
  const { data: session } = useSession();

  return (
    <Grid
      sx={{
        color: theme.palette.grey[50],
        background: theme.palette.grey[900],
        ...(session
          ? {
              padding: '25px 0 42px 90px',
              '@media (max-width: 960px)': {
                p: '10px 0 20px 90px',
                display: 'flex',
                flexDirection: 'column-reverse',
              },
            }
          : {
              p: '25px 0 42px 20px',
              '@media (max-width: 960px)': {
                p: '10px 0 20px 20px',
                display: 'flex',
                flexDirection: 'column-reverse',
              },
            }),
      }}
      container
    >
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <Box mb={8}>
          <Typography
            variant="h4"
            component={'a'}
            width="max-content"
            href={'/'}
            sx={{
              cursor: 'pointer',
              width: 'max-content',
              color: theme.palette.secondary.main,
              fontFamily: 'Winston SemiBold',
              '&:hover': {
                opacity: 0.8,
              },
            }}
          >
            П{' '}
            <span style={{ color: theme.palette.grey[50], fontSize: 20 }}>
              Подрядчик.рф
            </span>
          </Typography>
        </Box>
        <Box mb={1} />
        <Typography
          component={'a'}
          href={'/privacy/agreement'}
          sx={{
            cursor: 'pointer',
            width: 'max-content',
            opacity: 0.5,
            ':hover': { opacity: 1 },
          }}
        >
          Пользовательское соглашение
        </Typography>
        <Box mb={1} />
        <Typography
          component={'a'}
          display={'block'}
          href={'/privacy/policy'}
          sx={{
            cursor: 'pointer',
            width: 'max-content',
            opacity: 0.5,
            ':hover': { opacity: 1 },
          }}
        >
          Политика конфиденциальности
        </Typography>
        <Box mb={1} />
        <Typography
          component={'a'}
          display={'block'}
          href={'/doc'}
          sx={{
            cursor: 'pointer',
            width: 'max-content',
            opacity: 0.5,
            ':hover': { opacity: 1 },
          }}
        >
          Помощь
        </Typography>
        <Box mb={3} />
        <Typography
          sx={{
            opacity: 0.5,
            mt: 10,
          }}
        >
          Подрядчик.рф © 2023 Все права защищены
        </Typography>
      </Grid>
      <Grid item lg={9} md={8.5} sm={12} xs={12}>
        <Grid item container xs={12} flexWrap={'wrap'} p={2}>
          <Grid item lg={3} md={3} sm={4} xs={6} mb={2}>
            <Typography
              variant="h6"
              sx={{
                width: 'max-content',
                mb: 3,
                mr: 5,
              }}
            >
              Клиентам
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'companies?category=equipment'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Компании
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'#'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Заказы из Китая
            </Typography>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6} mb={2}>
            <Typography
              variant="h6"
              sx={{
                width: 'max-content',
                mb: 3,
                mr: 5,
              }}
            >
              Маркетплейс
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'marketplace?category=equipment'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Оборудование
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'marketplace?category=specialMachinery'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Спецтехника
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'marketplace?category=realty'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Недвижимость
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'marketplace?category=workService'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Работы и услуги
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'marketplace?category=product'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Изделия
            </Typography>

            <Typography
              component={'a'}
              display={'block'}
              href={'marketplace?category=buildingMaterial'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Стройматериалы
            </Typography>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6} mb={2}>
            <Typography
              variant="h6"
              sx={{
                width: 'max-content',
                mb: 3,
                mr: 5,
              }}
            >
              Тендеры
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'tenders?category=equipment'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Оборудование
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'tenders?category=specialMachinery'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Спецтехника
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'tenders?category=realty'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Недвижимость
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'tenders?category=workService'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Работы и услуги
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'tenders?category=product'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Изделия
            </Typography>
            <Typography
              component={'a'}
              display={'block'}
              href={'tenders?category=buildingMaterial'}
              mb={1.5}
              sx={{
                cursor: 'pointer',
                width: 'max-content',
                opacity: 1,
                ':hover': { opacity: 0.8 },
              }}
            >
              Стройматериалы
            </Typography>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6} mb={2}>
            <Typography
              variant="h6"
              sx={{
                width: 'max-content',
                mb: 3,
                mr: 5,
              }}
            >
              Контакты
            </Typography>
            <Box display={'flex'} alignItems={'center'} mb={1.5}>
              <PhoneIphoneIcon
                sx={{ mr: 2, color: theme.palette.secondary.main }}
              />
              <Box display={'flex'} flexDirection={'column'}>
                <Typography mb={1}>+7 8352 62-93-73</Typography>
                <Typography> +7 8352 62-33-73</Typography>
              </Box>
            </Box>
            <Box display={'flex'} alignItems={'center'} mb={1.5}>
              <EmailIcon sx={{ mr: 2, color: theme.palette.secondary.main }} />
              <Link
                href="mailto:info@подрядчик.рф"
                sx={{
                  cursor: 'pointer',
                  wordBreak: 'break-word',
                  opacity: 1,
                  ':hover': { opacity: 0.8, color: theme.palette.grey[50] },
                  color: theme.palette.grey[50],
                }}
              >
                info@подрядчик.рф
              </Link>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <LocationOnIcon
                sx={{ mr: 2, color: theme.palette.secondary.main }}
              />
              <Typography>
                Чебоксары, Чувашская Республика, ул.Карла Маркса, 58
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
